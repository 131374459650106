<script>
  export default {
    name: 'firstCourse',
    created () {
      if(process.env.NODE_ENV === 'development'){
       this.url = process.env.VUE_APP_VUE3_PLAN
      }else{
        this.url = window.location.origin + '/app-plan'
      }
    },
    data: () => {
      return {
        url: ''
      }
    }
  }
</script>

<template>
  <div class="firstCourse">
    <micro-app name="firstCourse"
               :url="url+'/firstCourse'"
               router-mode='native'
               baseroute="/app-plan/plan"
               keep-router-state
               keep-alive
               iframe>
    </micro-app>
  </div>
</template>

<style scoped lang="less">

</style>
